<template>
	<div>
		<HeaderPhone topTitle="资讯中心" style="position: fixed;"></HeaderPhone>
		<div style="height: 50px;"></div>
		<div class="banner"></div>
		<div style="padding: 50px 20px 20px;">
			<div v-for="item in newsList" :key='item.id' class="vue-flex newContent" @click="goNews(item.id)">
				<div style="margin-right: 15px;">
					<el-image class="animation_son" :src="item.image"></el-image>
				</div>
				<div>
					<div class="newTitle">{{item.title}}</div>
					<!-- <div class="newText">{{item.text}}</div> -->
					<div style="color: #B3B3B3;">{{item.data}}</div>
				</div>
			</div>
		</div>
		<FooterPhone></FooterPhone>
	</div>
</template>

<script>
import HeaderPhone from "@/components/headerPhone";
import FooterPhone from "@/components/footerPhone";
export default {
	data() {
		return {
			newsList: [
				{
					id: 1,
					image: require('@/static/imagesPhone/news/Rectangle 36.jpg'),
					title: '证监会：加大长期资金对股权科创基金的投资力度',
					// text: '推动相关行业主管部门完善法律法规，加大以社保基金、保险资金、养老基金等为代表的长期资金对股权科创基金的投资力度。',
					data: '2023-10-26'
				},
				{
					id: 2,
					image: require('@/static/imagesPhone/news/Rectangle 39.jpg'),
					title: '证监会：支持头部证券公司通过并购重组等方式做优做强 打造一流的投资银行',
					// text: '推动证券公司投行、投资、投研协同联动，不断提升服务实体经济、服务注册制改革能力，助力构建为实体企业提供多元化接力式金融服务体系。',
					data: '2023-11-03'
				},
				{
					id: 3,
					image: require('@/static/imagesPhone/news/Rectangle 37.jpg'),
					title: '广东设立新型储能产业天使投资基金',
					// text: '设立广东省新型储能产业天使投资基金，重点投向种子期、初创期、成长期科技型企业，建立早、中期科创和重大产业项目让利机制。用好政策性开发性金融工具、制造业中长期贷款等，支持新型储能项目建设。加快广州期货交易所电力和碳排放权期货品种研究和上市。',
					data: '2023-10-28'
				},
				{
					id: 4,
					image: require('@/static/imagesPhone/news/Rectangle 40.jpg'),
					title: '《广东省推进能源高质量发展实施方案（2023-2025年）》',
					// text: '推动多能源品种协调发展，加强多渠道、多主体能源供给，构建多元化能源保障体系，保障能源供应安全。',
					data: '2023-11-03'
				}
			],
		}
	},
	components: {
		HeaderPhone,
		FooterPhone,
	},
	methods: {
		goNews(id) {
			this.$router.push({
				path:'article',// 跳转
				query:{
					id // 参数
				}
			});
		}
	},
}
</script>

<style scoped>
.banner {
	height: 38vh;
	background: url("../static/imagesPhone/news/Mask group.jpg") no-repeat;
	background-size: cover;
	background-position: center center;
}
.animation_son {
	width: 133px;
	height: 66px;
}
.newTitle{
	font-weight: Semibold;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	overflow: hidden;
	-webkit-line-clamp: 2;
	white-space: normal;
	margin-bottom: 2px;
}
.newContent {
	margin-bottom: 20px;
}
</style>